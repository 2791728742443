<template>
  <div class="grzx-nav">
    <div class="grzx-nav-tx">
      <el-avatar
        :size="130"
        :src="'https://yuanpingjia-com-cn-1304761361.cos.ap-chongqing.myqcloud.com/banner/55236.jpg'"
      />
      <div class="grzx-nav-yhm-lever">
        <span class="grzx-nav-yhm-lever-num">{{ userinfoinxi.uvip }}</span>
      </div>
      <div class="grzx-nav-yhm">{{ userinfoinxi.username }}</div>
    </div>
    <div class="grzx-nav-st">
      <el-menu :default-active="activeindex" class="el-menu-vertical-demo">
        <div v-for="(item, key) in navlist" :key="key">
          <!-- 有下级 -->
          <el-sub-menu :index="item.index" v-if="item.children.length > 0">
            <template #title>
              <el-icon>
                <component :is="item.icon"></component>
              </el-icon>
              <span>{{ item.label }}</span>
            </template>
            <div v-for="(e, i) in item.children" :key="i">
              <!-- 有下级 -->
              <el-sub-menu :index="e.index" v-if="e.children.length > 0">
                <template #title>{{ e.label }}</template>
                <el-menu-item
                  :index="ele.index"
                  v-for="(ele, idx) in e.children"
                  :key="idx"
                  @click="clicknav(ele.index, ele.link)"
                  >{{ ele.label }}</el-menu-item
                >
              </el-sub-menu>
              <!-- 无下级 -->
              <el-menu-item
                :index="e.index"
                v-if="e.children.length == 0"
                @click="clicknav(e.index, e.link)"
                >{{ e.label }}</el-menu-item
              >
            </div>
          </el-sub-menu>
          <!-- 无下级 -->
          <el-menu-item
            v-if="item.children.length == 0"
            :index="item.index"
            @click="clicknav(item.index, item.link)"
          >
            <template #title>
              <el-icon>
                <component :is="item.icon"></component>
              </el-icon>
              <span>{{ item.label }}</span>
            </template>
          </el-menu-item>
        </div>
      </el-menu>
    </div>
  </div>
</template>

<script setup>
import { post, get } from "@/utils/index";
import { ElMessage } from "element-plus";
import { reactive, ref, onBeforeUnmount } from "vue";
import { useRouter } from "vue-router";
import {
  Document,
  Money,
  Menu as IconMenu,
  Location,
  Setting,
  Help,
} from "@element-plus/icons-vue";

const router = useRouter();

// 默认索引
let activeindex = ref("1");
let userinfoinxi = reactive({});
let navlist = reactive([
  {
    index: "1",
    icon: "Menu",
    link: "/grzx",
    label: "个人资料",
    children: [],
  },
  {
    index: "2",
    icon: "Document",
    link: "",
    label: "写作记录",
    children: [
      {
        index: "2-1",
        icon: "", 
        link: "",
        label: "论文写作",
        children: [
          {
            index: "2-1-1",
            icon: "",
            link: "/xzjl",
            label: "智言论文",
            children: [],
          },
          {
            index: "2-1-2",
            icon: "",
            link: "/lwjl",
            label: "慧语成章",
            children: [],
          },
          {
            index: "2-1-3",
            icon: "",
            link: "/gdlsjl",
            label: "观澜论道",
            children: [],
          },
          {
            index: "2-1-4",
            icon: "",
            link: "/mwfyjl",
            label: "墨舞风云",
            children: [],
          },
        ],
      },
      {
        index: "2-2",
        icon: "",
        link: "",
        label: "论文选题",
        children: [
          {
            index: "2-2-1",
            icon: "",
            link: "/xtbg/jzsjjl",
            label: "精准数据",
            children: [],
          },
           {
            index: "2-2-2",
            icon: "",
            link: "/xtjl",
            label: "详尽报告",
            children: [],
          },
        ],
      },
      {
        index: "2-3",
        icon: "",
        link: "",
        label: "技术报告",
        children: [
          {
            index: "2-3-1",
            icon: "",
            link: "/jsbg/lczyjl",
            label: "广西卫生",
            children: [],
          }
        ],
      },
    ],
  },
  {
    index: "3",
    icon: "Money",
    link: "/account",
    label: "账户信息",
    children: [],
  },
]);

// 获取用户信息和索引
const getuserinfo = () => {
  if (window.localStorage.getItem("navindex")) {
    activeindex.value = window.localStorage.getItem("navindex") || "1";
  }
  get("/api/User/GetTheUserInfo").then((result) => {
    Object.assign(userinfoinxi, result.data);
  });
};
getuserinfo();

// 获取会员拥有的菜单
const getvipmenu = () => {
  post("/api/UserJur/GetUserJur").then((res) => {
    if (res.data && res.data.length > 0) {
      let opt = {
        children: [],
      };
      res.data.forEach((e, i) => {
        // 只有一条直接追加
        if (res.data.length == 1) {
          opt.index = navlist.length + 1 + "";
          opt.icon = "Help";
          opt.link = e.vurl;
          opt.label = e.vname;
        } else {
          // 有多条追加二级菜单
          opt.index = navlist.length + 1 + "";
          opt.icon = "Help";
          opt.link = "";
          opt.label = "汇总记录";
          let tempopt = {};
          tempopt.index = navlist.length + 1 + "-" + (i + 1);
          tempopt.icon = "";
          tempopt.link = e.vurl;
          tempopt.label = e.vname;
          tempopt.children = [];
          opt.children.push(tempopt);
        }
      });
      navlist.push(opt);
    }
  });
};
getvipmenu();
// 点击索引
const clicknav = (index, url) => {
  // 保存索引
  window.localStorage.setItem("navindex", index);
  // 跳转链接
  router.push({
    path: url,
    query: {},
  });
};
</script>

<style scoped>
.grzx-nav {
  float: left;
  width: 240px;
  min-height: 600px;
  padding-right: 30px;
  box-sizing: border-box;
  border-right: 1px solid #ccc;
}
.grzx-con {
  float: right;
  width: calc(100% - 240px);
  height: 600px;
  box-sizing: border-box;
  padding-left: 30px;
}

.grzx-nav-tx {
  width: 100%;
  height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ccc;
}
.grzx-nav-yhm {
  width: calc(100% - 20px);
  padding: 0 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}
.grzx-nav-st {
  padding: 20px 0;
}
.grzx-nav-yhm-lever {
  position: relative;
  width: 40px;
  height: 40px;
  margin: 5px 0;
  background: url("/img/gerenzhongxin/VIP.png");
}
.grzx-nav-yhm-lever-num {
  position: absolute;
  left: 0;
  top: 17px;
  width: 40px;
  text-align: center;
  color: #fff;
  font-weight: bold;
}
</style>
