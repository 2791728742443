import { createStore } from "vuex";

export default createStore({
  state: {
    // 临时页码索引
    pageindextemp:1,
    // 导航索引
    navindex: -1,
    // 全部报纸属性
    baozhiall:[],
    // 类型
    contype: [
      {
        name: "报纸",
        value: 1,
      },
      {
        name: "期刊",
        value: 2,
      },
      {
        name: "会议",
        value: 3,
      },
    ],
  },
  // 修改属性
  mutations: {
    // 临时页码索引
    updatepageindextemp(state, status) {
      state.pageindextemp = status;
    },
    // 导航索引
    updateNavindex(state, status) {
      state.navindex = status;
    },
    // 全部报纸信息
    updatebaozhiall(state, status) {
      state.baozhiall = status;
    },
  },
  actions: {},
  modules: {},
});
