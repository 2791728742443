<template>
<!-- :key="$route.fullPath"地址不变参数变刷新 -->
  <router-view :key="$route.fullPath" /> 
</template>

<script setup>
import { ref, onMounted, getCurrentInstance } from 'vue'
onMounted(() => {
      document.dispatchEvent(new Event('render-event'))
    })
</script>

<style lang="scss">
#app {
  font-family:"微软雅黑", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
