<template>
  <div class="nyhead">
    <div class="nyhead-logo"><el-image
            style="width: 200px; height: 30px"
            src="/img/common/nylogo.png"
            fit="fill"
          />
    </div>
    <div class="nyhead-nav">
      <div class="nyhead-nav-list"><router-link to="/">论文助手</router-link></div>
      <div class="nyhead-nav-list" @click="shouyetiaozhuan('1-1','/xlw/tj')"><a href="javascript:;">产品大全</a></div>
    </div>
    <div class="nyhead-denglu" v-if="islogin==false">
      <div class="nyhead-nav-list-dl"><router-link to="/login">登录</router-link></div>
    </div>
    <div class="nyhead-denglu" v-if="islogin==true">
      <div class="nyhead-nav-list-dl" @click="clicktiaozhangezx">{{headusername}}</div>
      <div class="nyhead-nav-list-dl nyhead-nav-list-dl2" @click="loginout">退出</div>
    </div>
  </div>
</template>

<script setup>
import { Search } from "@element-plus/icons-vue";
import { post, get } from "@/utils/index";
import { ElMessage } from "element-plus";
import { ref } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();



let islogin=ref(false)
let headusername=ref('')


// 更改登录状态
const editlogin = () => {
  if (window.localStorage.getItem("token")) {
    islogin.value = true;
    headusername.value = window.localStorage.getItem("username");
  } else {
    islogin.value = false;
  }
};
editlogin();

// 跳转个人中心
const clicktiaozhangezx=()=>{
  window.localStorage.setItem("navindex", '1');
  router.push({
    path: '/grzx',
    query: {},
  });
}

// 跳转
const shouyetiaozhuan=(index, url)=>{
  // 保存索引
  window.localStorage.setItem("neirongnavindex", index);
  // 跳转链接
  router.push({
    path: url,
    query: {},
  });
}

// 注销
const loginout = () => {
  ElMessage({
    message: "已退出系统！",
    duration: 1000,
    onClose: () => {
      // 清除token
      window.localStorage.removeItem("token");
      // 清除个人信息
      window.localStorage.removeItem("username");
      window.localStorage.removeItem("userInfo");
      // 清除url
      window.localStorage.removeItem("loginurl");
      editlogin();
      router.push({
        path: "/",
        query: {},
      });
    },
  });

};






</script>

<style scoped>
.nyhead {
  width: 100%;
  height: 52px;
  background: #2489F2;
  padding: 0 50px;
  box-sizing: border-box;
}
.nyhead-logo{
  float: left;
  margin-top: 11px;
}
.nyhead-nav{
  float: left;
  height: 100%;
}
.nyhead-nav:after{
  content: '';
  display: table;
  clear: both;
}
.nyhead-nav-list{
  float: left;
  width: 100px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nyhead-nav-list a:link,.nyhead-nav-list a:visited{
  color: #fff;
  text-decoration: none;
}
.nyhead-nav-list a:hover{
  font-weight: bold;
}
.nyhead-denglu{
  float: right;
}
.nyhead-nav-list-dl{
  float: left;
  width: 150px;
  height: 100%;
  text-align: center;
  line-height: 52px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #fff;
  cursor: pointer;
}
.nyhead-nav-list-dl:hover{
  text-decoration: underline;
  font-weight: bold;
}
.nyhead-nav-list-dl a:link,.nyhead-nav-list-dl a:visited{
  color: #fff;
  text-decoration: none;
}
.nyhead-nav-list-dl a:hover{
  font-weight: bold;
}
.nyhead-nav-list-dl2{
  width: 100px;
  color: #fff;
  cursor: pointer;
}
.nyhead-nav-list-dl2:hover{
  font-weight: bold;
}
</style>
