<template>
  <div class="main">
    <heads />
    <div class="banner">
      <div class="banner-mian">
        <div class="banner-title">
          <div class="banner-title-main">
            论文助手专业写论文<br />不止于写论文
          </div>
          <div class="banner-title-sb">
            解决论文问题，一个软件就够了，不用东奔西跑；找资料，查重复率，<br />修改降重，格式规范...你要的一应俱全
          </div>
          <div class="banner-img-btn">
            <router-link to="###"><el-button size="large">开始写作</el-button></router-link>
            <router-link to="###"><el-button size="large" type="primary">智言论文</el-button></router-link>
          </div>
        </div>
        <div class="banner-img">
          <el-image
            style="width: 600px; height: 591px"
            src="/img/home/bannerimg.png"
            fit="fill"
          />
        </div>
      </div>
    </div>
    <div class="mokuai">
      <div class="mokuai-con">
        <div class="ysy-txt">
          <div class="ysy-txt-dbt">01</div>
          <div class="ysy-txt-zbt">高效智能，个性化定制</div>
          <div class="ysy-txt-img">
            <el-image
              style="width: 300px; height: 6px"
              src="/img/home/heav_xbt.png"
              fit="fill"
            />
          </div>
          <div class="ysy-txt-ms">
            AI算法快速分析文献，生成高质量论文初稿，节省研究时间与精力。并根据用户需求调整写作风格与深度，确保论文符合学术规范与个人研究需求。
          </div>
          <div class="ysy-txt-btn">
            <el-button type="primary" size="large" round>了解详情</el-button>
          </div>
        </div>
        <div class="ysy-img">
          <el-image
            style="width: 550px; height: 577px"
            src="/img/home/mk1.png"
            fit="fill"
          />
        </div>
      </div>
    </div>

    <div class="mokuai2">
      <div class="mokuai-con">
        <div class="yse-txt">
          <div class="ysy-txt-dbt2">02</div>
          <div class="ysy-txt-zbt">第三代 AI智能写作系统</div>
          <div class="ysy-txt-img">
            <el-image
              style="width: 300px; height: 6px"
              src="/img/home/heav_xbt.png"
              fit="fill"
            />
          </div>
          <div class="ysy-txt-ms">
            基于transformer的attention机制实现高精准度模型，构建全新网络结构的训练引擎，全文通顺度较上一代提升45%
          </div>
          <div class="ysy-txt-btn">
            <el-button type="primary" size="large" round>了解详情</el-button>
          </div>
        </div>
        <div class="yse-img">
          <el-image
            style="width: 480px; height: 433px"
            src="/img/home/mk2.png"
            fit="fill"
          />
        </div>
      </div>
    </div>

    <div class="mokuai3">
      <div class="mokuai-con">
        <div class="zycc-title">专业论文写作全新升级</div>
        <div class="zycc-ul">
          <div class="zycc-list">
            <div class="zycc-list-img">
              <el-image
                style="width: 150px;"
                src="/img/home/zy1.png"
                fit="fill"
              />
            </div>
            <div class="zycc-list-txt">数据升级</div>
            <div class="zycc-list-subtxt">
              新增各类中外文学术论文5亿，网页数据动态更新，总量高达500亿写作痕迹，数据写作库业界全网覆盖
            </div>
          </div>
          <div class="zycc-list">
            <div class="zycc-list-img">
              <el-image
                style="width: 150px;"
                src="/img/home/zy2.png"
                fit="fill"
              />
            </div>
            <div class="zycc-list-txt">算法升级</div>
            <div class="zycc-list-subtxt">
              为了节约同学时间成本，避免来回写作，论文助手系统深入研究各类版本算法，推出一个系统五种报告，花一份钱就够了
            </div>
          </div>
          <div class="zycc-list">
            <div class="zycc-list-img">
              <el-image
                style="width: 150px;"
                src="/img/home/zy3.png"
                fit="fill"
              />
            </div>
            <div class="zycc-list-txt">写作引擎升级</div>
            <div class="zycc-list-subtxt">
              全新对比引擎高效稳定，写作准确，不虚飘，不漏飘，能够对比图片、表格、公式、代码进行有效写作
            </div>
          </div>
          <div class="zycc-list">
            <div class="zycc-list-img">
              <el-image
                style="width: 150px;"
                src="/img/home/zy4.png"
                fit="fill"
              />
            </div>
            <div class="zycc-list-txt">服务升级</div>
            <div class="zycc-list-subtxt">
              论文助手专业写论文，不止于写论文；针对论文整个周期所遇到的问题推出各类专业化解决方案
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mokuai4">
      <div class="mokuai-con">
        <div class="zycc-title">更多强大专业论文工具</div>
        <div class="gongju-ul">
          <div class="gongju-list">
            <el-image
                style="width: 50px; height:50px"
                src="/img/home/tool_icon1.png"
                fit="fill"
              />
            <div class="gongju-txt">病例报告</div>
          </div>
          <div class="gongju-list">
            <el-image
                style="width: 50px; height:50px"
                src="/img/home/tool_icon2.png"
                fit="fill"
              />
            <div class="gongju-txt">病例报告Kimi</div>
          </div>
          <div class="gongju-list">
            <el-image
                style="width: 50px; height:50px"
                src="/img/home/tool_icon3.png"
                fit="fill"
              />
            <div class="gongju-txt">病例长报告</div>
          </div>
          <div class="gongju-list">
            <el-image
                style="width: 50px; height:50px"
                src="/img/home/tool_icon4.png"
                fit="fill"
              />
            <div class="gongju-txt">小袁专用</div>
          </div>
        </div>
        <div class="gongju-ul">
          <div class="gongju-list">
            <el-image
                style="width: 50px; height:50px"
                src="/img/home/tool_icon5.png"
                fit="fill"
              />
            <div class="gongju-txt">智言论文</div>
          </div>
          <div class="gongju-list">
            <el-image
                style="width: 50px; height:50px"
                src="/img/home/tool_icon6.png"
                fit="fill"
              />
            <div class="gongju-txt">搜汇期刊</div>
          </div>
          <div class="gongju-list">
            <el-image
                style="width: 50px; height:50px"
                src="/img/home/tool_icon7.png"
                fit="fill"
              />
            <div class="gongju-txt">话术管理</div>
          </div>
          <div class="gongju-list">
            <el-image
                style="width: 50px; height:50px"
                src="/img/home/tool_icon8.png"
                fit="fill"
              />
            <div class="gongju-txt">语气管理</div>
          </div>
          
        </div>
      </div>
    </div>

    <div class="mokuai5">
      <div class="mokuai-con">
        <div class="zycc-title2">论文助手专业写论文，不止于写论文</div>
        <div class="zycc-bgm"></div>
        <div class="zycc-btn"><router-link to="###"><el-button type="primary" size="large" round>开始写作</el-button></router-link></div>
      </div>
    </div>
    <footers />

  </div>
</template>

<script setup>
import { get } from "@/utils/index";
import { ElMessage } from "element-plus";
import { ref, reactive, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
const { proxy } = getCurrentInstance();
const router = useRouter();

// 跳转
const tioazhaunyemian = (links) => {
  const url = router.resolve({
    path: links,
    query: {},
  });
  window.open(url.href);
};
</script>

<style scoped>
:deep(.el-button){
  margin: 0 20px 0 0;
}
:deep(.banner-img-btn .el-button--large) {
  --el-button-size: 44px;
  padding: 15px 50px;
  font-size: 14px;
  border-radius: 10px;
}
.main {
  position: relative;
  width: 100%;
  height: 100%;
}
.banner {
  width: 100%;
  height: 700px;
}
.banner-title-main {
  font-size: 45px;
  font-weight: bold;
}
.banner-mian {
  position: relative;
  margin: auto;
  width: 1200px;
  height: 700px;
}
.banner-title {
  position: absolute;
  left: 30px;
  top: 160px;
}
.banner-title-sb {
  margin-top: 30px;
  line-height: 30px;
}
.banner-img {
  position: absolute;
  right: 30px;
  top: 50px;
}
.banner-img-btn {
  margin-top: 50px;
}

.mokuai {
  width: 100%;
  height: 670px;
  background: #f9fbff;
}
.mokuai-con {
  position: relative;
  width: 1200px;
  height: 100%;
  margin: auto;
}
.ysy-txt {
  position: absolute;
  left: 0;
  top: 0;
  width: 550px;
}
.ysy-img {
  position: absolute;
  right: 30px;
  top: 40px;
}
.ysy-txt-dbt {
  font-size: 90px;
  font-weight: bold;
  color: #ecf8ff;
  margin-top: 100px;
}
.ysy-txt-dbt2 {
  font-size: 90px;
  font-weight: bold;
  color: #ecf8ff;
  margin-top: 50px;
}
.ysy-txt-zbt {
  font-size: 30px;
  margin-top: 10px;
}
.ysy-txt-ms {
  font-size: 14px;
  color: #4b4b4b;
  line-height: 30px;
  text-align: justify;
  margin-bottom: 30px;
}
.ysy-txt-img {
  margin-bottom: 50px;
}
.mokuai2 {
  width: 100%;
  height: 560px;
  background: #fff;
}
.yse-txt {
  position: absolute;
  right: 0;
  top: 0;
  width: 550px;
}
.yse-img {
  position: absolute;
  left: 30px;
  top: 40px;
}
.mokuai3 {
  width: 100%;
  height: 584px;
  background:  #f9fbff;
}
.zycc-title {
  font-size: 30px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 80px;
  padding-top: 100px;
}
.zycc-title2 {
  font-size: 30px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 40px;
  padding-top: 100px;
}
.zycc-list{
  width: 260px;
  height: auto;
}
.zycc-list-img{
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.zycc-list-txt{
  color: #333;
      font-size: 18px;
    font-weight: 600;
    text-align: center;
    margin: 30px 0;
}
.zycc-list-subtxt{
      font-size: 14px;
    color: #999999;
    line-height: 25px;
    text-align: center;
}
.zycc-ul{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.mokuai4 {
  width: 100%;
  height: 520px;
  background:  #f9fbff;
}
.gongju-list{
  width:275px;
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.gongju-txt{
      font-size: 16px;
    font-weight: 600;
    line-height: 30px;
}
.gongju-ul{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}
.mokuai5{
  width: 100%;
  height:290px;
  background:  #f9fbff;
}
.zycc-bgm{
  position: absolute;
  left: 100px;
  top: 73px;
  width: 1000px;
  height: 217px;
  background: url('/img/home/pro_bg.png');
  background-size: 100% 100%;
}
.zycc-btn{
  position: relative;
  z-index: 90;
  text-align: center;
}
</style>
