<template>
  <div class="ny-cebianlan">
    <div class="ny-cebianlan-logo"></div>
    <div class="ny-cebianlan-ul">
      <el-menu :default-active="activeindex" class="el-menu-vertical-demo">
        <div v-for="(item, key) in navlist" :key="key">
          <!-- 有下级 -->
          <el-sub-menu :index="item.index" v-if="item.children.length > 0">
            <template #title>
              <el-icon>
                <component :is="item.icon"></component>
              </el-icon>
              <span>{{ item.label }}</span>
            </template>
            <div v-for="(e, i) in item.children" :key="i">
              <!-- 有下级 -->
              <el-sub-menu :index="e.index" v-if="e.children.length > 0">
                <template #title>{{ e.label }}</template>
                <el-menu-item
                  :index="ele.index"
                  v-for="(ele, idx) in e.children"
                  :key="idx"
                  @click="tiaozhuanlianjie(ele.index, ele.link)"
                  >{{ ele.label }}</el-menu-item
                >
              </el-sub-menu>
              <!-- 无下级 -->
              <el-menu-item
                :index="e.index"
                v-if="e.children.length == 0"
                @click="tiaozhuanlianjie(e.index, e.link)"
                >{{ e.label }}</el-menu-item
              >
            </div>
          </el-sub-menu>
          <!-- 无下级 -->
          <el-menu-item
            v-if="item.children.length == 0"
            :index="item.index"
            @click="tiaozhuanlianjie(item.index, item.link)"
          >
            <template #title>
              <el-icon>
                <component :is="item.icon"></component>
              </el-icon>
              <span>{{ item.label }}</span>
            </template>
          </el-menu-item>
        </div>
      </el-menu>
    </div>
  </div>
</template>

<script setup>
import {
  Edit,
  Wallet,
  Brush,
  Notebook,
} from "@element-plus/icons-vue";
import { post, get } from "@/utils/index";
import { ElMessage, ElMessageBox } from "element-plus";
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

// 默认索引
let activeindex = ref('1-1');
let navlist = reactive([
  {
    index: "1",
    icon: "Edit",
    link: "",
    label: "论文写作",
    children: [
      {
        index: "1-1",
        icon: "",
        link: "/xlw/tj",
        label: "智言论文",
        children: [],
      },
      {
        index: "1-2",
        icon: "",
        link: "/lunwen",
        label: "慧语成章",
        children: [],
      },
      {
        index: "1-3",
        icon: "",
        link: "/gdls",
        label: "观澜论道",
        children: [],
      },
      {
        index: "1-4",
        icon: "",
        link: "/mwfy",
        label: "墨舞风云",
        children: [],
      },
    ],
  },
  {
    index: "2",
    icon: "Brush",
    link: "",
    label: "论文选题",
    children: [
      {
        index: "2-4",
        icon: "",
        link: "/lwxt/ty",
        label: "选题体验",
        children: [],
      },
      {
        index: "2-1",
        icon: "",
        link: "/lwxt",
        label: "简洁选题",
        children: [],
      },
      {
        index: "2-2",
        icon: "",
        link: "/xtbg/jzsj",
        label: "精准数据",
        children: [],
      },
      {
        index: "2-3",
        icon: "",
        link: "/xtbg",
        label: "详尽报告",
        children: [],
      },
      
    ],
  },
  {
    index: "3",
    icon: "Wallet",
    link: "",
    label: "技术报告",
    children: [
      {
        index: "3-1",
        icon: "",
        link: "/jsbg/lczy",
        label: "广西卫生",
        children: [],
      },
    ],
  },

]);
// 获取索引
const getnavindex = () => {
   if (window.localStorage.getItem("neirongnavindex")) {
    activeindex.value = window.localStorage.getItem("neirongnavindex") || '1-1';
  }
};
getnavindex();

// 点击索引
const tiaozhuanlianjie = (index, url) => {
  // 保存索引
  window.localStorage.setItem("neirongnavindex", index);
  // 跳转链接
  router.push({
    path: url,
    query: {},
  });
};




</script>

<style scoped>
:deep(.el-menu){
  border-right: 0;
}
/* 侧边栏 */
.ny-cebianlan {
  position: absolute;
  left: 0;
  top: 0;
  width: 200px;
  height: 100%;
  background: #fff;
  overflow-y: scroll;
  padding: 10px 0 10px 5px;
  box-sizing: border-box;
}
.ny-cebianlan-li {
  width: 100%;
  height: 34px;
  display: flex;
  align-items: center;
  font-size: 14px;
  box-sizing: border-box;
  padding: 0 10px;
  border-radius: 4px;
  margin-top: 5px;
  cursor: pointer;
}
.ny-cebianlan-li-active{
  font-weight: bold;
  color: #409efc;
  background: #f0f4fc;
  cursor: default;
}
.ny-cebianlan-li:hover {
  background: #f5f8fe;
}
.ny-cebianlan-li > span {
  margin-left: 8px;
}
.ny-cebianlan-li:hover > span {
  color: #409efc;
}
</style>
