<template>
  <div class="footer">
      渝公网安备：50011902000454号 重庆域田数据科技有限公司©版权所有
  </div>
</template>

<script setup>
import { post } from "@/utils/index";
import { ElMessage } from "element-plus";
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

// const clickhuidaoshouye = () => {
//   router.push({
//     path: "/",
//     query: {},
//   });
// };
</script>
<style scoped>
.footer {
  width: 100%;
  height: 60px;
  font-size: 14px;
  background: #272D36;
  box-sizing: border-box;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
