<template>
  <div class="nyhead">
    <div class="nyhead-logo"><el-image
            style="width: 200px; height: 30px"
            src="/img/common/nylogo.png"
            fit="fill"
          />
    </div>
    <div class="nyhead-nav">
      <div class="nyhead-nav-list"><router-link to="/">论文助手</router-link></div>
    </div>
  </div>
</template>

<script setup>
import { Search } from "@element-plus/icons-vue";
import { post, get } from "@/utils/index";
import { ElMessage } from "element-plus";
import { ref, defineProps } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
</script>

<style scoped>
.nyhead {
  width: 100%;
  height: 52px;
  background: #2489F2;
  padding: 0 50px;
  box-sizing: border-box;
}
.nyhead-logo{
  float: left;
  margin-top: 11px;
}
.nyhead-nav{
  float: left;
  height: 100%;
}
.nyhead-nav:after{
  content: '';
  display: table;
  clear: both;
}
.nyhead-nav-list{
  float: left;
  width: 100px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nyhead-nav-list a:link,.nyhead-nav-list a:visited{
  color: #fff;
  text-decoration: none;
}
.nyhead-nav-list a:hover{
  font-weight: bold;
}
.nyhead-denglu{
  float: right;
}
.nyhead-nav-list-dl{
  float: left;
  width: 100px;
  height: 100%;
text-align: center;
line-height: 52px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.nyhead-nav-list-dl a:link,.nyhead-nav-list-dl a:visited{
  color: #fff;
  text-decoration: none;
}
.nyhead-nav-list-dl a:hover{
  font-weight: bold;
}

</style>
