import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// 使用vue-meta-info插件，改变link、title、meta等
import MetaInfo from "vue-meta-info";
// element
import ElementPlus from "element-plus";
import * as ElIcon from "@element-plus/icons-vue"; //icon
import "element-plus/dist/index.css";
import locale from "element-plus/lib/locale/lang/zh-cn"; //中文
// 顶部底部等公共部分
import heads from "./components/common/head.vue";
import nyheads from "./components/common/neiyehead.vue";
import nyheadsndl from "./components/common/neiyeheadnodenglu.vue";
import footers from "./components/common/footer.vue";
import footernew from "./components/common/footernew.vue";
import islogin from "./components/common/islogin.vue";
import grzxnav from "./components/common/gerenzhongxinnav.vue";
import nrnav from "./components/common/neirongnav.vue";


//前置守卫 动态设置title 关键字
router.beforeEach((to, from, next) => {
  const tmcontent: any = to.meta.content;

  if (to.matched.length === 0) {
    // 如果未匹配到路由，则重定向到首页
    from.name ? next({ name: from.name }) : next("/");
  }
  if (tmcontent) {
    const head = document.getElementsByTagName("head");
    const meta = document.createElement("meta");
    const keywordss = document.querySelector('meta[name="keywords"]');
    if (keywordss) {
      keywordss.setAttribute("content", tmcontent.keywords);
    }
    const descriptionss = document.querySelector('meta[name="description"]');
    if (descriptionss) {
      descriptionss.setAttribute("content", tmcontent.description);
    }

    meta.content = tmcontent;
    head[0].appendChild(meta);
  }
  const tmtitle: any = to.meta.title;
  if (tmtitle) {
    document.title = tmtitle;
  }
  next();
});

// echarts
import * as echarts from "echarts";
const app = createApp(App);
app.config.globalProperties.$echarts = echarts;
// 图片ip地址
app.config.globalProperties.$imgurl =
  "https://yuanpingjia-com-cn-1304761361.cos.ap-chongqing.myqcloud.com";

Object.keys(ElIcon).forEach((key) => {
  app.component(key, ElIcon[key]);
});

app
    .component("heads", heads)
    .component("nyheadsndl", nyheadsndl)
    .component("islogin", islogin)
    .component("nyheads", nyheads)
    .component("footers", footers)
    .component("grzxnav", grzxnav)
    .component("nrnav", nrnav)
    .component("footernew", footernew)
  .use(store)
  .use(router)
  .use(MetaInfo)
  // .use(ElementPlus)
  .use(ElementPlus, { locale })
  .mount("#app");
