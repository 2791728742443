<template>
  <div class="head">
    <div class="head-con">
      <div class="logo">
        <el-image
          @click="clicklogo"
          style="width: 200px; height: 68px"
          src="/img/common/logo.png"
          fit="fill"
        />
      </div>
      <div class="nav">
        <div class="nav-list" @click="shouyetiaozhuan('1-1','/xlw/tj')"><a href="javascript:;">产品大全</a></div>
        <div class="nav-login" v-if="islogin==true">
            <div class="nav-login-user">
                <svg t="1721026380381" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12555" width="20" height="20"><path d="M516.5568 507.6992m-453.5808 0a453.5808 453.5808 0 1 0 907.1616 0 453.5808 453.5808 0 1 0-907.1616 0Z" fill="#D480F1" p-id="12556"></path><path d="M970.1376 507.6992c0-8.4992-0.256-16.9984-0.7168-25.3952l-136.2432-129.792-55.04 66.3552-240.4864-183.1936L432.128 501.8112 251.1872 349.4912 196.1472 407.552l134.9632 219.392-24.0128 41.7792 42.0864 50.176-46.7456 33.1776 121.7024 199.7824a454.12352 454.12352 0 0 0 92.416 9.4208c250.5216 0 453.5808-203.0592 453.5808-453.5808z" fill="#A861E0" p-id="12557"></path><path d="M805.0176 342.6816c-22.2208 0-40.2432 18.0224-40.2432 40.2432 0 7.936 2.3552 15.36 6.2976 21.6064l-130.048 80.7936-102.912-183.552a40.192 40.192 0 0 0 16.9984-32.768 40.24832 40.24832 0 0 0-80.4864 0 40.1408 40.1408 0 0 0 16.896 32.7168L391.7824 488.3456 259.84 408.0128a39.95136 39.95136 0 0 0 8.8576-25.1392 40.24832 40.24832 0 0 0-80.4864 0c0 22.2208 18.0224 40.2432 40.2432 40.2432 2.048 0 3.9936-0.2048 5.9392-0.512l72.6528 246.1184h415.6416l72.4992-246.8864c3.1232 0.768 6.4 1.2288 9.7792 1.2288 22.2208 0 40.2432-18.0224 40.2432-40.2432 0.0512-22.1184-17.9712-40.1408-40.192-40.1408zM700.3136 769.28H328.2944c-15.7696 0-28.5184-12.7488-28.5184-28.5184v-1.4848c0-15.7696 12.7488-28.5184 28.5184-28.5184h372.0192c15.7696 0 28.5184 12.7488 28.5184 28.5184v1.4848c0 15.7696-12.7488 28.5184-28.5184 28.5184z" fill="#FFFFFF" p-id="12558"></path></svg>
                <span @click="clicktiaozhangezx">{{headusername}}</span>
            </div>
            <div class="nav-login-loginout">
                <el-button type="warning" @click="loginout">退出</el-button>
            </div>
        </div>
        <div class="nav-no-login" v-if="islogin==false">
            <router-link to="/login"><el-button>立即登录</el-button></router-link>
            <a href="javascript:;"><el-button type="primary" @click="shouyetiaozhuan('1-1','/xlw/tj')">智言论文</el-button></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Search } from "@element-plus/icons-vue";
import { post, get } from "@/utils/index";
import { ElMessage } from "element-plus";
import { ref, defineProps } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();

let islogin=ref(false)
let headusername=ref('')


// 更改登录状态
const editlogin = () => {
  if (window.localStorage.getItem("token")) {
    islogin.value = true;
    headusername.value = window.localStorage.getItem("username");
  } else {
    islogin.value = false;
  }
};
editlogin();

// 跳转
const shouyetiaozhuan=(index, url)=>{
  // 保存索引
  window.localStorage.setItem("neirongnavindex", index);
  // 跳转链接
  router.push({
    path: url,
    query: {},
  });
}

// 跳转个人中心
const clicktiaozhangezx=()=>{
  window.localStorage.setItem("navindex", '1');
  router.push({
    path: '/grzx',
    query: {},
  });
}

// 注销
const loginout = () => {
  ElMessage({
    message: "已退出系统！",
    duration: 1000,
    onClose: () => {
      // 清除token
      window.localStorage.removeItem("token");
      // 清除个人信息
      window.localStorage.removeItem("username");
      window.localStorage.removeItem("userInfo");
      // 清除url
      window.localStorage.removeItem("loginurl");
      window.localStorage.removeItem("navindex");
      editlogin();
      router.push({
        path: "/",
        query: {},
      });
    },
  });

  // post("/member/logout").then((res) => {
  //   // 清除token
  //   window.localStorage.removeItem("token");
  //   // 清除个人信息
  //   window.localStorage.removeItem("userInfo");
  //   editlogin();
  //   ElMessage({
  //     message: "已退出系统！",
  //     duration: 1000,
  //     onClose: () => {
  //       router.push({
  //         path: "/",
  //         query: {},
  //       });
  //     },
  //   });
  // });
};




</script>

<style scoped>
:deep(.el-button){
  margin: 0 0 0 20px;
}
.head {
  width: 100%;
  height: 68px;
  background: #fff;
  border-bottom: 1px solid #ddd;
}
.head-con {
  width: 1200px;
  height: 100%;
  margin: auto;
}
.logo {
  width: 200px;
  height: 68px;
  float: left;
}
.nav{
    width:1000px;
    height: 68px;
    float: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.nav-list{
    width: 120px;
    height: 66px;
    text-align: center;
    line-height: 66px;
    border-bottom: 2px solid #fff;
}
.nav-list:hover{
    border-bottom: 2px solid #268AF3;
}
.nav-list a:link,.nav-list a:visited{
    text-decoration: none;
    color: #333;
}
.nav-login{
    width: 250px;
    height: 68px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
}
.nav-no-login{
    width: 250px;
    height: 68px;
    display: flex;
    justify-content:flex-end;
    align-items: center;
    margin-left: 20px;
}
.nav-login-user{
    width: 180px;
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.nav-login-user > span{
  width: auto;
    max-width: 150px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 10px;
    cursor: pointer;
}
.nav-login-user > span:hover{
    color: #268AF3;
    text-decoration: underline;
}
.nav-login-loginout{
    width: 70px;
    height: 68px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
</style>
